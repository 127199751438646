import React from 'react';
import {useFirebaseAuth} from "./components/FirebaseAuth";
import {signOut} from "firebase/auth";

function App() {
  // The HTML element to render the firebase UI.
  const firebaseUIElemRef = React.useRef(null);
  // @ts-ignore Continue the initializing pending redirect event.
  const onPendingRedirect = React.useCallback((firebaseRef) => {
    firebaseRef.current.ui.start(firebaseUIElemRef.current, firebaseRef.current.uiConfig);
  }, [firebaseUIElemRef]);
  // The firebase auth global instance.
  const {firebaseReady, firebaseCurrentUser, firebaseRef} = useFirebaseAuth({onPendingRedirect});

  return <>
    <div ref={firebaseUIElemRef}></div>
    {!firebaseReady && <div>Loading...</div>}
    {firebaseReady && !firebaseCurrentUser && <button onClick={() => {
      console.log(`firebase: global instance user sign-in.`);
      // @ts-ignore
      firebaseRef.current.ui.start(firebaseUIElemRef.current, firebaseRef.current.uiConfig);
    }}>Sign in</button>}
    {firebaseReady && firebaseCurrentUser && <>
      <p>Current user: {firebaseCurrentUser.email}</p>
      <button onClick={() => {
        // @ts-ignore
        signOut(firebaseRef.current.auth).then(() => {
          console.log(`firebase: global instance user logout.`);
        });
      }}>Sign out</button>
    </>}
  </>;
}

export default App;
