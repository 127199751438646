import React from 'react';
import 'firebaseui/dist/firebaseui.css';
import {initializeApp} from "firebase/app";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import {User, EmailAuthProvider, GoogleAuthProvider, GithubAuthProvider, PhoneAuthProvider} from "firebase/auth";
import {auth as firebaseuiAuth} from 'firebaseui';

// @ts-ignore
export function useFirebaseAuth({onPendingRedirect}) {
  // If not initialized, should never render the root, which means the whole app is waiting for firebase.
  const [firebaseInitialized, setFirebaseInitialized] = React.useState<boolean>(false);
  // If we can determine user login or no user, firebase is ready to show sign-up or sign-in or logout.
  // If not ready, should never render the main application.
  const [firebaseReady, setFirebaseReady] = React.useState<boolean>(false);
  // If got a user, it should never be null.
  // It stores the type User
  const [firebaseCurrentUser, setFirebaseCurrentUser] = React.useState<User|null>(null);
  // The underlayer global instance of firebase.
  const firebaseRef = React.useRef({
    auth: null,
    ui: null,
    uiConfig: null,
    initIsPendingRedirect: null,
  });

  // Setup the global singleton instance.
  React.useEffect(() => {
    if (firebaseRef.current.ui) return console.log(`firebase: global instance init ignored, already done.`);
    console.log(`firebase: global instance init starting.`);

    const firebaseConfig = {
      apiKey: "AIzaSyC12c01xVO8aC_SOvYTfFb3QVAVySnpVgQ",
      authDomain: "ossrs-app.firebaseapp.com",
      projectId: "ossrs-app",
      storageBucket: "ossrs-app.appspot.com",
      messagingSenderId: "202539215957",
      appId: "1:202539215957:web:178f7e158eb3e1ce9c78dc",
      measurementId: "G-QJV070QG3M"
    };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const ui = new firebaseuiAuth.AuthUI(auth);
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult: any, redirectUrl: string) => {
          console.log('firebase: signInSuccessWithAuthResult', authResult, redirectUrl);
          // @ts-ignore
          firebaseRef.current.ui.reset();
          return false;
        },
        signInFailure: (error: any) => {
          console.log('firebase: signInFailure', error);
        }
      },
      signInFlow: 'popup',
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        GithubAuthProvider.PROVIDER_ID,
        {provider: EmailAuthProvider.PROVIDER_ID, signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD},
        PhoneAuthProvider.PROVIDER_ID,
      ]
    };

    const o = firebaseRef.current = {
      // @ts-ignore
      auth, ui, uiConfig,
      // @ts-ignore Must store this state, because it will change after ui start.
      initIsPendingRedirect: ui.isPendingRedirect(),
    };
    console.log(`firebase: global instance init done, pending=${o.initIsPendingRedirect}.`);

    // No pending redirect, firebase is initialized and ready.
    if (!o.initIsPendingRedirect) {
      setFirebaseInitialized(true);
      return;
    }

    // For new user in pending redirect, logout current user if any.
    signOut(auth).then(() => {
      console.log(`firebase: global instance init logout, pending=${o.initIsPendingRedirect}.`);
      setFirebaseInitialized(true);
    });
  }, [firebaseRef, setFirebaseInitialized, setFirebaseCurrentUser]);

  // Handle global auth state change event.
  React.useEffect(() => {
    if (!firebaseInitialized) return;

    // @ts-ignore
    const unregister = onAuthStateChanged(firebaseRef.current.auth, (user: User|null) => {
      // Note that if pending, we should never set to ready util user is not null or error.
      const userNotReady = firebaseRef.current.initIsPendingRedirect && !user;
      // If user is null, no user is logged in. If user is not null, a user is logged in.
      if (!userNotReady) {
        setFirebaseReady(true);
        console.log(`firebase: application ready`);
      }
      // @ts-ignore
      console.log(`firebase: onAuthStateChanged, pending=${firebaseRef.current.initIsPendingRedirect}, user is`, user);

      // @ts-ignore
      setFirebaseCurrentUser(user);
    }, (error: Error) => {
      console.log(`firebase: onAuthStateChanged, error`, error);
    });
    console.log(`firebase: global instance hook onAuthStateChanged`);

    return () => {
      console.log(`firebase: global instance unhook onAuthStateChanged`);
      unregister();
    };
  }, [firebaseRef, firebaseInitialized, setFirebaseReady, setFirebaseCurrentUser]);

  // Continue the initializing pending redirect event.
  React.useEffect(() => {
    if (!firebaseInitialized || !firebaseRef.current.initIsPendingRedirect) return;
    console.log(`firebase: continue pending redirect`);
    onPendingRedirect && onPendingRedirect(firebaseRef);
  }, [firebaseRef, firebaseInitialized, onPendingRedirect]);

  return {
    firebaseInitialized,
    firebaseReady,
    firebaseCurrentUser,
    firebaseRef,
  };
}
